import { Component, Input } from '@angular/core';
import { ESectionColor } from '../sections/section.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-formats',
  templateUrl: './formats.component.html',
  styleUrl: './formats.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class FormatsComponent {
  @Input() public color?: ESectionColor;

  public readonly sectionColor: typeof ESectionColor = ESectionColor;
}
