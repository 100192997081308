import { Component, input } from '@angular/core';
import { IContent } from 'src/app/content/content.interface';
import { IDataPayloadMultiple } from 'src/app/core/http/http.interface';
import { ESectionColor } from '../section.interface';
import { IContentMenuItem } from '../../main-navigation/main-navigation.interface';
import { ESortOrder } from '../../search-bar/search-bar.interface';
import { RouterModule } from '@angular/router';
import { ContentTitleItemComponent } from '../../content-title-item/content-title-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-content-categories-section',
  templateUrl: './content-categories-section.component.html',
  styleUrl: './content-categories-section.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    ContentTitleItemComponent,
  ],
})
export class ContentCategoriesSectionComponent {
  industries$ = input.required<IDataPayloadMultiple<IContentMenuItem>>();
  militaries$ = input.required<IDataPayloadMultiple<IContentMenuItem>>();
  guides$ = input.required<IDataPayloadMultiple<IContent>>();

  public readonly color: typeof ESectionColor = ESectionColor;
  public readonly sortOrder: typeof ESortOrder = ESortOrder;
}
