<div class="formats formats--{{ color }}">
  <div class="formats__row row">
    <div class="formats__square-container col-12 col-sm-6 col-md-8">
      <div class="formats__square formats__square--horizontal formats__square--pocket-guide">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_8b486ce321.webp"
          alt="A QuickSeries pocket guide cover."
          width="800"
          height="1042">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Pocket Guides

            <span class="formats__square-title-pill qs-pill qs-pill--red qs-pill--small">
              Popular
            </span>
          </p>

          <div class="formats__square-desc">
            <p>
              With durable lamination and heavy-duty spiral binding, our
              pocket guides deliver a long-lasting reference experience to
              your community.
            </p>

            <ul>
              <li>To-the-point content and simple language</li>
              <li>Perfect for the field, office or classroom</li>
              <li>Durable and water-resistant</li>
            </ul>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/pocket-guides'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-6 col-md-4">
      <div class="formats__square formats__square--vertical formats__square--custom">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_Frame_650_156aee950a.webp"
          alt="Three QuickSeries pocket guides next to each other, demonstrating 3 different customization options."
          width="840"
          height="396">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Customize your content
          </p>

          <div class="formats__square-desc">
            <p>
              Personalization has the power to enhance customer engagement,
              increase brand loyalty, and boost conversion rates.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material'"
              [fragment]="'custom-print'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_0e9fb45ee6.webp"
          alt="The cover of a customized QuickSeries desk guide by Eastern Michigan University titled Survivor handbook with a photo of trees"
          width="838"
          height="970">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Desk Guides
          </p>

          <div class="formats__square-desc">
            <p>
              Make a bigger impact with our desk guides.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/desk-guides'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_dd9050b356.webp"
          alt="The QuickSeries Pocket Card front panel titled Cannabidiol (CBD) Get the Facts"
          width="764"
          height="1076">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Pocket Cards
          </p>

          <div class="formats__square-desc">
            <p>
              Multiple panels offer more space for your message.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/pocket-cards'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_96f203524b.webp"
          alt="Front panel of the Interview preparedness for service members quickseries 8 panel wallet card with tips on what to bring and how to dress for the interview"
          width="574"
          height="1076">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Wallet Cards
          </p>

          <div class="formats__square-desc">
            <p>
              Wallet cards offer flexibility and promote topic awareness.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/wallet-cards'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_6cbb9ab4d8.webp"
          alt="One side of a QuickSeries double sided hurricane preparedness timeline poster with an infographic style detailing a timeline plan"
          width="826"
          height="1096">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Posters
          </p>

          <div class="formats__square-desc">
            <p>
              Provide informative content with an eye-catching design.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/posters'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_80b704a7bf.webp"
          alt="A quickseries magnet titled beat high blood pressure showing icons on how to be more heart healthy"
          width="750"
          height="1124">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Badge Cards & Magnets
          </p>

          <div class="formats__square-desc">
            <p>
              Badge cards & magnets provide a visual reminder of your message.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/badge-cards-and-magnets'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_352da169cc.webp"
          alt="A dynamic layout of the quickseries trifold pamphlet titled Time to quit helping people stop smoking"
          width="650"
          height="1154">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Pamphlets
          </p>

          <div class="formats__square-desc">
            <p>
              The most familiar outreach format.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/pamphlets'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_92770d84d4.webp"
          alt="The U.S. army Garrison-Miami personalized quickseries folder with internal pockets cover showing the garrison"
          width="898"
          height="1152">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Folders
          </p>

          <div class="formats__square-desc">
            <p>
              Our custom folders make premium takeaways.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/folders'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="formats__square-container col-12 col-sm-4">
      <div class="formats__square formats__square--vertical">
        <img
          loading="lazy"
          class="formats__square-img"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/small_hero_696f4b2edc.webp"
          alt="The month of September of the Veterans Affairs personalized content calendar detailing support for veterans and service members while showing monthly observances"
          width="822"
          height="1176">

        <div class="formats__square-details">
          <p class="formats__square-title">
            Calendars
          </p>

          <div class="formats__square-desc">
            <p>
              Reinforce your message all year long.
            </p>

            <a
              class="formats__square-link"
              [routerLink]="'/products/print-solutions-outreach-material/calendars'">

              Find out more

              <span
                class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"
                [class.qs-icon--white]="color === sectionColor.DARK_BLUE">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
