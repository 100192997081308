<section class="content-categories-section section section--white-blue">
  <div class="container-md">
    <p class="section__witty">
      Dependable and Concise
    </p>

    <h2 class="section__title">
      Rely on us for current, accurate, and trusted content.
    </h2>

    <div class="section__description section__description--no-columns">
      <p>
        QuickSeries is the smart choice for your content needs. Our unmatched
        quality, ease of use, cost-effectiveness, and proven track record make
        us the go-to resource for professionals across industries. We cover a
        wide range of subjects, from military to healthcare to emergency preparedness.
      </p>
    </div>

    <div class="content-categories-section__container">
      <div class="content-categories-section__sub-container">
        <div class="content-categories-section__link-group">
          <div>
            <p class="content-categories-section__group-label">
              Industries
            </p>

            @if (industries$()) {
              <ul class="content-categories-section__links no-list">
                @for (link of industries$().data; track link) {
                  <li>
                    <a
                      class="content-categories-section__link"
                      [routerLink]="['/', 'content', 'industry', link.attributes.slug]"
                      [queryParams]="{ sortOrder: sortOrder.FEATURED }">

                      {{ link.attributes.name }}
                    </a>
                  </li>
                }
              </ul>
            }
          </div>
        </div>

        <div class="content-categories-section__link-group">
          <p class="content-categories-section__group-label">
            Military
          </p>

          @if (militaries$()) {
            <ul class="content-categories-section__links no-list">
              @for (link of militaries$().data; track link) {
                <li>
                  <a
                    class="content-categories-section__link"
                    [routerLink]="['/', 'content', 'military', link.attributes.slug]"
                    [queryParams]="{ sortOrder: sortOrder.FEATURED }">

                    {{ link.attributes.name }}
                  </a>
                </li>
              }
            </ul>
          }
        </div>
      </div>

      <div class="content-categories-section__link-group">
        <p class="content-categories-section__group-label">
          New & trending
        </p>

        @if (guides$()) {
          <ul class="content-categories-section__new-guides no-list">
            @for (guide of guides$().data; track guide) {
              <li>
                <app-content-title-item
                  [content]="guide.attributes"
                  [color]="color.WHITE_BLUE">
                </app-content-title-item>
              </li>
            }
          </ul>
        }
      </div>
    </div>
  </div>
</section>
