import { IImagePayload } from 'src/app/core/http/http.interface';
import { IDataPayloadSingle } from 'src/app/core/http/http.interface';

export enum EPrintSolution {
  POCKET_GUIDES = 'pocket-guides',
  WALLET_CARDS = 'wallet-cards',
  DESK_GUIDES = 'desk-guides',
  PAMPHLETS = 'pamphlets',
  POSTERS = 'posters',
  BADGE_CARDS_MAGNETS = 'badge-cards-and-magnets',
  CALENDARS = 'calendars',
  FOLDERS = 'custom-folders',
  POCKET_CARDS = 'pocket-cards',
}

export interface IPrintSolution {
  type: EPrintSolution;
  name: string;
  description: string;
  image: IDataPayloadSingle<IImagePayload>;
  routerLink: string;
}
